<template>
  <div>
    <el-dialog v-model="view" width="1000px" :close-on-click-modal="false" :close-on-press-escape="false"
               :show-close="false">
      <el-steps :active="query.Steps" class="steps" simple finish-status="success" process-status="finish">
        <el-step title="奖金发放发起"></el-step>
        <el-step title="签署发起"></el-step>
        <el-step title="确认发起签字"></el-step>
        <el-step title="签字完成"></el-step>
        <el-step title="完成归档"></el-step>
      </el-steps>
      <el-button class="Details" @click="openDetails(query.id)">奖金详情</el-button>
      <div v-if="query.StepsStart=='未生成文档'">
        <el-button size="small" @click="OpenFinance" type="info" icon="el-icon-user">选择财务人员</el-button>
      </div>
      <div v-if="query.StepsStart=='完成签署'">
        <el-button icon="el-icon-s-unfold" @click="openPayTool()">奖金发放管理</el-button>
      </div>
      <el-form label-width="80px" class="principal">
        <el-row :gutter="20">
          <el-col :span="10">
            <el-form-item label="项目昵称">
              {{ query.Title }}
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="活动昵称">
              {{ query.TitleName }}
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-form label-width="140px">
        <el-form-item label="财务人员（签署）" :required="true">
          <div class="FinanceUser" v-for="(item,index) in form.Finance" :key="index">
            {{ item.Name }} - {{ item.Mobile }} <i class="el-icon-delete" @click="rmFinance(index)"  v-if="query.StepsStart=='未生成文档'"></i>
          </div>
        </el-form-item>
      </el-form>
      <el-form label-width="110px">
        <el-form-item label="奖励发放方式" :required="true">
          <el-checkbox :disabled="query.StepsStart!=='未生成文档'" v-model="form.Amount.contribution">支付宝</el-checkbox>
          <el-checkbox :disabled="query.StepsStart!=='未生成文档'" v-model="form.Amount.other">其他方式</el-checkbox>
        </el-form-item>
      </el-form>
      <el-row>
        <el-col :span="12">
          <el-form label-width="110px">
            <el-form-item label="机构签署主体">
            </el-form-item>
          </el-form>
          <el-form label-width="115px">
            <el-form-item label="机构名称">
              <el-input v-model="form.Mech.Name" disabled class="Mech"></el-input>
            </el-form-item>
            <el-form-item label="社会信用代码">
              <el-input v-model="form.Mech.AccountId" disabled class="Mech"></el-input>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="12">
          <el-form label-width="115px">
            <el-form-item class="f">f</el-form-item>
          </el-form>
          <el-form label-width="115px">
            <el-form-item label="机构负责人">
              <el-input v-model="form.Mech.UserName" disabled class="Mech"></el-input>
            </el-form-item>
            <el-form-item label="机构手机号码">
              <el-input v-model="form.Mech.UserMobile" disabled class="Mech"></el-input>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
      <SigningStatus ref="SigningStatus" v-if="query.StepsStart=='签署中'" :data="SignersUserSum"></SigningStatus>
      <template #footer>
        <el-button type="success" plain @click="confirmOK" v-if="query.StepsStart=='完成签署'">确认归档</el-button>
        <el-button @click="initiate" type="success" plain class="warning" icon="el-icon-tickets"
                   v-if="query.StepsStart=='文档已生成'">发起签署
        </el-button>
        <el-button @click="lookDoc" type="primary" plain class="warning" icon="el-icon-tickets"
                   v-if="query.StepsStart=='文档已生成'">查看签署文档
        </el-button>
        <el-button @click="generate" type="warning" class="warning" icon="el-icon-tickets"
                   v-if="query.StepsStart=='未生成文档'">生成签署文档
        </el-button>
        <el-button @click="deleteDatas" type="danger" plain class="warnings" icon="el-icon-document-delete"
                   v-if="query.StepsStart=='未生成文档'">重置
        </el-button>
        <el-button @click="deleteDatas" type="danger" plain class="warnings" icon="el-icon-document-delete"
                   v-if="query.StepsStart=='文档已生成'">重置数据
        </el-button>
        <el-button @click="flows" type="success" plain class="warnings" icon="el-icon-check"
                   v-if="query.StepsStart=='签署中'">确认归档
        </el-button>
        <el-button @click="revoke" type="danger" plain class="warnings" icon="el-icon-error"
                   v-if="query.StepsStart=='签署中'">签署撤回
        </el-button>
        <el-button @click="lookDocs" plain class="warnings" icon="el-icon-document-copy"
                   v-if="query.StepsStart=='奖金已发放'">查看签署文件
        </el-button>
        <el-button @click="close">关闭</el-button>
      </template>
    </el-dialog>
    <DetailsType ref="DetailsType"></DetailsType>
    <PayTool ref="PayTool"></PayTool>
    <Finance ref="Finance"></Finance>
  </div>

</template>

<script>
import DetailsType from '../com/DetailsType'
import PayTool from '../com/PayTool'
import Finance from '../com/Finance'
import SigningStatus from "./SigningStatus";
import {Generate, ResetData, Initiate, revokeSign, setflows, ConfirmOK} from '../../../api/Bonus/Cpproval'
import {openFileId, openFlowId} from "../../../api/Contract";
import {SignersUser} from "../../../api/Integral/Cpproval";

export default {
  data() {
    return {
      view: false,
      CommitteeStart: '',
      query: {
        Steps: 2
      },
      //参与财务人员签署
      form: {
        //奖励方式
        Amount: {
          cash: false,
          contribution: true,
          other: false
        },
        //机构
        Mech: {
          UserName: '',
          UserMobile: '',
          AccountId: '',
          Name: '',
          ThirdPartyUserId: ''
        },
        Finance: [],
        //项目进度及成果
        SpeedText: '',
      },
      SignersUserSum:[]
    }
  },
  methods: {
    getSigners(){
      let FlowId=this.query.FlowId

      SignersUser({FlowId}).then(res=>{
        let sum=res.sum
        this. SignersUserSum=sum
      })
    },
    rmFinance(index) {
      this.$confirm("是否确认删除该标签吗？", "提示", {
        type: "warning"
      }).then(() => {
        let FinanceData = this.form.Finance
        FinanceData.splice(index, 1)
        this.form.Finance = FinanceData
      })
    },
    AddFinance(Name, Mobile) {
      let Finance = this.form.Finance
      Finance.push({Name, Mobile})
      this.form.Finance = Finance
    },
    OpenFinance() {
      let App_id = this.query.App_id
      this.$refs.Finance.open(App_id)
    },
    //确认归档
    confirmOK() {
      let App_id = this.query.App_id
      let id = this.query.id
      let UserInfo = this.$store.state.UserInfo
      let Admin_id = UserInfo.Admin_id
      this.$confirm("是否确认归档该奖金？", "提示", {
        type: "warning"
      }).then(() => {
        this.openLoading('处理中')
        ConfirmOK({App_id, id, Admin_id}).then(res => {
          this.closeLoading()
          if (res.code == 0) {
            this.$message.success(res.message);
            this.$parent.getData()
            this.close()
          } else {
            this.$message.error(res.message);
          }
        })
      })
    },
    openPayTool() {
      let App_id = this.query.App_id
      let id = this.query.id
      this.$refs.PayTool.open(App_id, id)
    },
    lookDocs() {
      let FlowId = this.query.FlowId
      this.openLoading()
      openFlowId({FlowId}).then(res => {
        this.closeLoading()
        if (res.code == 0) {
          window.open(res.fileUrl)
        } else {
          this.$message.error(res.message);
        }
      })
    },
    flows() {
      let App_id = this.query.App_id
      let id = this.query.id

      let UserInfo = this.$store.state.UserInfo
      let Admin_id = UserInfo.Admin_id
      this.$confirm("是否确认归档？", "提示", {
        type: "warning"
      }).then(() => {
        this.openLoading('处理中')
        setflows({App_id, Admin_id, id}).then(res => {
          this.closeLoading()
          if (res.code == 0) {
            this.$message.success(res.message);
            let sum = res.sum
            this.query = sum
            this.query.Steps = Number(this.query.Steps)
            this.$parent.getData()
          } else {
            this.$message.error(res.message);
          }
        })
      }).catch()
    },
    revoke() {
      let App_id = this.query.App_id
      let id = this.query.id

      let UserInfo = this.$store.state.UserInfo
      let Admin_id = UserInfo.Admin_id
      this.$confirm("是否确认撤销并终止该签署流程吗？", "提示", {
        type: "warning"
      }).then(() => {
        this.openLoading('处理中')
        revokeSign({App_id, Admin_id, id}).then(res => {
          this.closeLoading()
          if (res.code == 0) {
            this.$message.success(res.message);
            let sum = res.sum
            this.query = sum
            this.query.Steps = Number(this.query.Steps)
            this.$parent.getData()
            this.deleteData()
          } else {
            this.$message.error(res.message);
          }
        })
      }).catch()
    },
    initiate() {
      let UserInfo = this.$store.state.UserInfo
      let Admin_id = UserInfo.Admin_id
      let App_id = this.query.App_id
      let id = this.query.id

      this.$confirm("是否确认发起签署？", "提示", {
        type: "warning"
      }).then(() => {
        this.openLoading()
        Initiate({Admin_id, App_id, id}).then(res => {
          this.closeLoading()
          if (res.code == 0) {
            this.$message.success(res.message);
            let sum = res.sum
            this.query = sum
            let Esign = sum.Esign
            Esign = JSON.parse(Esign)
            this.form = Esign
            this.query.Steps = Number(this.query.Steps)
            this.$parent.getData()
            this.getSigners()
          } else {
            this.$message.error(res.message);
          }
        })
      })
    },
    lookDoc() {
      let FileId = this.query.FileId

      this.openLoading()
      openFileId({FileId}).then(res => {
        this.closeLoading()
        if (res.code == 0) {
          window.open(res.downloadUrl)
        } else {
          this.$message.error(res.message);
        }
      })
    },
    generate() {
      this.openLoading()
      let form = this.form
      let UserInfo = this.$store.state.UserInfo
      let Admin_id = UserInfo.Admin_id
      let App_id = this.query.App_id
      let id = this.query.id

      Generate({id, App_id, Admin_id, form}).then(res => {
        this.closeLoading()
        if (res.code == 0) {
          this.$message.success(res.message);
          let sum = res.sum
          this.query = sum
          let Esign = sum.Esign
          Esign = JSON.parse(Esign)
          this.form = Esign
          this.query.Steps = Number(this.query.Steps)
          this.$parent.getData()
        } else {
          this.$message.error(res.message);
        }
      })
    },
    CommitteeAdd(data) {
      let CommitteeStart = this.CommitteeStart
      if (CommitteeStart == '参与') {
        this.form.signersData.push(data)
        this.CommitteeStart = ''
      } else if (CommitteeStart == '抄送') {
        this.form.CCData.push(data)
        this.CommitteeStart = ''
      }
    },
    close() {
      this.deleteData()
      this.view = false
    },
    deleteDatas() {
      let UserInfo = this.$store.state.UserInfo
      let Admin_id = UserInfo.Admin_id
      let id = this.query.id
      ResetData({Admin_id, id}).then(res => {
        if (res.code == 0) {
          this.$message.success(res.message);
          let sum = res.sum
          this.query = sum
          let Esign=JSON.parse(this.query.Esign)
          this.form = Esign
          this.query.Steps = Number(this.query.Steps)
          // this.form = form
          this.$parent.getData()
        } else {
          this.$message.error(res.message);
        }
      })
    },
    //重置数据
    deleteData() {
      let Esign=JSON.parse(this.query.Esign)
      this.form = Esign

    },
    handlSelected(data) {
      this.form.BeFor.Name = data.Name
      this.form.BeFor.Mobile = data.Mobile
      this.form.BeFor.User_id = data.User_id
    },
    handleSign(data) {
      this.form.Mech.AccountId = data.AccountId
      this.form.Mech.Name = data.Name
      this.form.Mech.ThirdPartyUserId = data.ThirdPartyUserId
      this.form.Mech.UserName = data.UserName
      this.form.Mech.UserMobile = data.UserMobile
    },
    openMech() {
      this.$refs.SignMech.open()
    },
    openDetails(TypeData) {
      let data = JSON.parse(TypeData)
      this.$refs.DetailsType.open(data)
    },
    open(data) {
      let form = JSON.parse(JSON.stringify(data))
      let Esign = form.Esign
      Esign = JSON.parse(Esign)
      let Finance = Esign.Finance
      if (!Finance) {
        Esign.Finance = []
      }
      this.form = Esign
      this.query = form
      this.view = true
      this.query.Steps = Number(this.query.Steps)


      if (this.query.StepsStart=='签署中'){
        this.getSigners()
      }
    },
    OpenUser() {
      this.$refs.ChoiceUser.handlAdd()
    },
    openLoading() {
      this.loading = this.$loading({
        lock: true,//lock的修改符--默认是false
        text: '处理中',//显示在加载图标下方的加载文案
        spinner: 'el-icon-loading',//自定义加载图标类名
        background: 'rgba(0, 0, 0, 0.7)',//遮罩层颜色
      });
    },
    closeLoading() {
      this.loading.close();
    },
  },
  components: {
    DetailsType,
    PayTool,
    Finance,
    SigningStatus
  }
}
</script>

<style scoped lang="scss">
.topss {
  position: relative;
}

.Details {
  margin-bottom: 10px;
}

.topFn {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 50;
  position: absolute;
  left: 0;
  top: 0;
}

.steps {
  margin-bottom: 20px;
}

.principal {
  margin-top: 20px;
}

.Mech {
  width: 300px;
}

.MechS {
  margin-bottom: 20px;
}

.warning {
  float: left;
  margin-left: 20px;
}

.warnings {
  float: left;
  margin-left: 20px;
}

.f {
  opacity: 0;
}

.el-icon-delete {
  margin-left: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  color: red;
}

.FinanceUser {
  display: inline-block;
  margin-right: 10px;
}
</style>