<template>
  <el-dialog v-model="view" width="600px" title="批量发放结果" :close-on-click-modal="false">
    <el-table
        :data="data"
        height="500px"
        border
    >
      <el-table-column prop="Name" label="姓名"></el-table-column>
      <el-table-column prop="Mobile" label="手机号码"></el-table-column>
      <el-table-column label="总金额">
        <template #default="scope">
          {{ scope.row.ToNumber }} 元
        </template>
      </el-table-column>
      <el-table-column label="结果">
        <template #default="scope">
          <el-tag type="success" v-if="scope.row.Start=='转账成功'">{{ scope.row.Start }}</el-tag>
          <el-tag type="warning" v-else>{{ scope.row.Start }}</el-tag>
        </template>
      </el-table-column>
    </el-table>
    <template #footer>
                <span class="dialog-footer">
                    <el-button @click="close">关闭</el-button>
                </span>
    </template>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      view: false,
      data: []
    }
  },
  methods: {
    close() {
      this.data = []
      this.view = false
    },
    open(data) {
      this.data = data
      this.view = true
    }
  }
}
</script>

<style scoped>

</style>