<template>
  <el-dialog v-model="view" width="600px" :close-on-click-modal="false">
    <el-form label-width="80px">
      <el-form-item label="姓名">{{ data.getUser.Name }}</el-form-item>
      <el-form-item label="手机号码">{{ data.getUser.Mobile }}</el-form-item>
      <el-form-item label="金额">{{ data.getUser.ToNumber }}元</el-form-item>
    </el-form>
    <el-form label-width="80px">
      <el-form-item label="发放方式">
        <el-input v-model="form.Channel" disabled></el-input>
      </el-form-item>
      <el-form-item label="支付工具" :required="true">
        <el-input v-model="form.PayTool"></el-input>
      </el-form-item>
      <el-form-item label="支付凭证" :required="true">
        <el-radio v-model="form.Radio" label="文本" class="Voucher" @change="cloesVoucher">文本（订单编号）</el-radio>
        <el-radio v-model="form.Radio" label="图片" @change="cloesVoucher">图片</el-radio>
        <el-input v-model="form.Voucher" v-if="form.Radio=='文本'"></el-input>
        <img v-if="form.Radio=='图片'&&form.Voucher" :src="form.Voucher" class="avatar">
        <el-upload
            v-if="form.Radio=='图片'"
            class="upload-block"
            accept="image/png,image/jpg,image/jpeg"
            :action='actionUrl'
            :show-file-list="false"
            :on-success="handleAvatarSuccess"
            :before-upload="beforeAvatarUpload">
          <el-button type="default" size="small">上传</el-button>
        </el-upload>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="confirm" type="success">确认</el-button>
        <el-button @click="close">取消</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import {Actions} from "../../../api/ProjectAdmin/NumberAdmin";
import {OtherPay} from '../../../api/Bonus/Cpproval'

export default {
  data() {
    return {
      actionUrl: '',
      view: false,
      query: {
        App_id: '',
        id: '',
      },
      data: {
        getUser: {},
        index: ''
      },
      form: {
        index: '',
        Channel: '其他方式',
        PayTool: '',
        Radio: '',
        Voucher: ''
      }
    }
  },
  mounted() {
    this.actionUrl = Actions()
  },
  methods: {
    cloesVoucher() {
      this.form.Voucher = ''
    },
    //确认进行发放
    confirm() {
      this.$confirm("是否进行发放？", "提示", {
        type: "warning"
      }).then(() => {
        let form = this.form
        let App_id = this.query.App_id
        let id = this.query.id
        let data = this.data

        let UserInfo = this.$store.state.UserInfo
        let Admin_id = UserInfo.Admin_id
        OtherPay({form, App_id, id, Admin_id, data}).then(res => {
          if (res.code == 0) {
            this.$message.success(res.message);
            this.$parent.getdata()
            this.close()
          } else {
            this.$message.error(res.message);
          }
        })
      })
    },
    close() {
      this.form.Channel = '其他方式'
      this.form.PayTool = ''
      this.form.Radio = ''
      this.form.index = ''
      this.form.Voucher = ''
      this.view = false
    },
    open(getUser, index, App_id, id) {
      this.view = true
      this.data.getUser = getUser
      this.data.index = index
      this.query.App_id = App_id
      this.query.id = id
    },
    handleAvatarSuccess(res) {
      this.form.Voucher = res.imgfile;
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg' || 'image/png';
      const isLt2M = file.size / 1024 / 1024 < 20;

      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG 格式!');
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 20MB!');
      }
      return isJPG && isLt2M;
    },
  }
}
</script>

<style scoped lang="scss">
.avatar {
  width: 400px;
  height: auto;
  display: block;
  margin-top: 10px;
  margin-bottom: 10px;
}

.upload-block {
  ::v-deep .el-upload--text {
    width: auto;
    height: auto;
    border: none;
  }
}

.changeImg .avatar-uploader .el-upload {
  border-radius: 50%;
}

.Voucher {
  margin-bottom: 20px;
}
</style>