import request from '../../utils/request';

/**
 * 奖金确认管理
 * @param query
 * @returns {AxiosPromise}
 */

//查询数据
export const fetchData = query => {
    return request({
        url:process.env.VUE_APP_URL+ 'Bonus/Cpproval/find',
        method: 'get',
        params: query
    });
};


//查询奖金管理详情支付数据
export const fetchChanneData = query => {
    return request({
        url:process.env.VUE_APP_URL+ 'Bonus/Cpproval/find/ChannelData',
        method: 'get',
        params: query
    });
};


//不通过申请
export const setfail = query => {
    return request({
        url:process.env.VUE_APP_URL+ 'Bonus/Cpproval/fail',
        method: 'post',
        data: query
    });
};

//生成签署文档
export const Generate = query => {
    return request({
        url:process.env.VUE_APP_URL+ 'Bonus/Cpproval/generate',
        method: 'post',
        data: query
    });
};

//重置数据
export const ResetData = query => {
    return request({
        url:process.env.VUE_APP_URL+ 'Bonus/Cpproval/resetData',
        method: 'post',
        data: query
    });
};






//发起签署
export const Initiate = query => {
    return request({
        url:process.env.VUE_APP_URL+ 'Bonus/Cpproval/initiate',
        method: 'post',
        data: query
    });
};

//终止签署流程
export const revokeSign = query => {
    return request({
        url:process.env.VUE_APP_URL+ 'Bonus/Cpproval/revoke',
        method: 'post',
        data: query
    });
};

export const setflows = query => {
    return request({
        url:process.env.VUE_APP_URL+ 'Bonus/Cpproval/flows',
        method: 'post',
        data: query
    });
};


//查询奖金详情内容
export const FindBonus = query => {
    return request({
        url:process.env.VUE_APP_URL+ 'Bonus/Cpproval/find/Bonus',
        method: 'get',
        params: query
    });
};


//对个人之间转账
export const GetUserOK = query => {
    return request({
        url:process.env.VUE_APP_URL+ 'Bonus/Cpproval/Bonus/User/Pay',
        method: 'post',
        data: query
    });
};

//批量转账
export const BatchOK = query => {
    return request({
        url:process.env.VUE_APP_URL+ 'Bonus/Cpproval/Bonus/Batch/Pay',
        method: 'post',
        data: query
    });
};


//确认归档
export const ConfirmOK = query => {
    return request({
        url:process.env.VUE_APP_URL+ 'Bonus/Cpproval/Bonus/ConfirmOK',
        method: 'post',
        data: query
    });
};




//确认其他方式
export const OtherPay = query => {
    return request({
        url:process.env.VUE_APP_URL+ 'Bonus/Cpproval/Bonus/OtherPay',
        method: 'post',
        data: query
    });
};

//批量确认其他方式
export const OtherPayAll = query => {
    return request({
        url:process.env.VUE_APP_URL+ 'Bonus/Cpproval/Bonus/OtherPayAll',
        method: 'post',
        data: query
    });
};







