`
<template>
  <div>
    <el-dialog v-model="view" width="1100px">
      <el-table
          :data="data"
          height="500px"
          border
          class="tableClass"
          header-cell-class-name="table-header"
          @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55" :selectable="selectAble"></el-table-column>
        <el-table-column prop="Name" label="姓名"></el-table-column>
        <el-table-column prop="Mobile" label="手机号码"></el-table-column>
        <el-table-column label="总金额">
          <template #default="scope">
            {{ scope.row.ToNumber }} 元
          </template>
        </el-table-column>
        <el-table-column label="状态">
          <template #default="scope">
            <el-tag type="warning" v-if="scope.row.Start=='未发放'">{{ scope.row.Start }}</el-tag>
            <el-tag type="success" v-else>{{ scope.row.Start }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column fixed="right">
          <template #default="scope">
            <el-button type="text" v-if="scope.row.Start=='未发放'" @click="OpenGrant(scope.row,scope.$index)">支付宝APi发放
            </el-button>
            <el-button type="text" v-if="scope.row.Start=='未发放'" @click="OpenOtherPay(scope.row,scope.$index)">其他方式发放
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <template #footer>
                <span class="dialog-footer">
                    <el-button plain class="batch" @click="batch">批量转账（支付宝）</el-button>
                    <el-button plain class="batch" @click="otherbatch">批量转账（其他方式）</el-button>
                    <el-button @click="close">关闭</el-button>
                </span>
      </template>
    </el-dialog>
    <el-dialog v-model="other" width="600px" title="核对个人信息" :close-on-click-modal="false"
               :close-on-press-escape="false">
      <el-form label-width="80px" class="principal">
        <el-form-item label="姓名">{{ getUser.Name }}</el-form-item>
        <el-form-item label="手机号码">{{ getUser.Mobile }}</el-form-item>
        <el-form-item label="金额">{{ getUser.ToNumber }}元</el-form-item>
      </el-form>
      <el-form label-width="120px" class="principal">
        <el-form-item label="注意:" class="fnRed">
          当该账户无法正常发放奖金时，请使用下面其他账户和姓名进行发放，两则必填
        </el-form-item>
        <el-form-item label="其他账户发放">
          <el-switch
              v-model="fnRed"
              active-color="#13ce66"
              inactive-color="#ff4949">
          </el-switch>
        </el-form-item>
        <el-form-item label="账号" v-if="fnRed">
          <el-input v-model="form.Mobile"></el-input>
        </el-form-item>
        <el-form-item label="姓名" v-if="fnRed">
          <el-input v-model="form.Name"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
                <span class="dialog-footer">
                     <el-button @click="getUserOK">确定</el-button>
                    <el-button @click="GetUserclose">取消</el-button>
                </span>
      </template>
    </el-dialog>
    <PaySum ref="PaySum"></PaySum>
    <OtherPay ref="OtherPay"></OtherPay>
    <OtherDetailsType ref="OtherDetailsType"></OtherDetailsType>
  </div>
</template>


<script>
import {FindBonus, GetUserOK, BatchOK} from '../../../api/Bonus/Cpproval'
import PaySum from './PaySum'
import OtherPay from './OtherPay'
import OtherDetailsType from './OtherDetailsType'

//索引坐标
// scope.$index

export default {
  data() {
    return {
      data: [],
      other: false,
      view: false,
      fnRed: false,
      //核对信息表（准备发放）
      getUser: {},
      multipleSelection: [],
      form: {
        Mobile: "",
        Name: ""
      },
      query: {
        App_id: '',
        id: '',
      }
    }
  },
  components: {
    PaySum,
    OtherPay,
    OtherDetailsType
  },
  methods: {
    otherbatch() {
      let Selection = this.multipleSelection
      if (Selection.length >= 1) {
        let App_id = this.query.App_id
        let id = this.query.id
        this.$refs.OtherDetailsType.open(App_id, Selection,id)
      } else {
        this.$message.error('请选中用户！');
      }
    },
    // 使用其他方式进行发放
    OpenOtherPay(getUser, index) {
      let App_id = this.query.App_id
      let id = this.query.id
      this.$refs.OtherPay.open(getUser, index, App_id, id)
    },
    //确定进行发放奖金
    getUserOK() {
      let fnRed = this.fnRed
      let form = this.form
      let App_id = this.query.App_id
      let id = this.query.id

      let UserInfo = this.$store.state.UserInfo
      let Admin_id = UserInfo.Admin_id
      this.$confirm("是否确认发放该奖金？", "提示", {
        type: "warning"
      }).then(() => {
        this.openLoading('处理中')
        GetUserOK({fnRed, form, Admin_id, App_id, id}).then(res => {
          this.closeLoading()
          if (res.code == 0) {
            this.$message.success(res.message);
            this.GetUserclose()
            this.$parent.getData()
            this.getdata()
          } else {
            this.$message.error(res.message);
          }
        })
      })
    },
    //关闭奖金发放界面
    GetUserclose() {
      this.getUser = {}
      this.other = false
    },
    //打开奖金发放界面
    OpenGrant(data, index) {
      this.other = true
      this.getUser = data
      this.form.index = index
    },
    selectAble(row) {
      if (row.Start == '未发放') {
        return true;
      } else {
        return false;
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    //批量转账
    batch() {
      let App_id = this.query.App_id
      let id = this.query.id

      let UserInfo = this.$store.state.UserInfo
      let Admin_id = UserInfo.Admin_id

      let Selection = this.multipleSelection
      if (Selection.length >= 1) {
        this.$confirm("是否确认批量发放该奖金？", "提示", {
          type: "warning"
        }).then(() => {
          this.openLoading('批量处理中')
          BatchOK({App_id, id, Admin_id, Selection}).then(res => {
            this.closeLoading()
            if (res.code == 0) {
              let PaySum = res.PaySum
              this.multipleSelection = []
              this.$message.success(res.message);
              this.getdata()
              this.$refs.PaySum.open(PaySum)
            } else {
              this.$message.error(res.message);
            }
          })
        })
      } else {
        this.$message.error('请选中用户！');
      }

    },
    getdata() {
      this.openLoading()
      let App_id = this.query.App_id
      let id = this.query.id
      FindBonus({id, App_id}).then(res => {
        this.closeLoading()
        let sum = res.sum
        let ToNumber = res.ToNumber
        this.data = sum
        this.ToNumber = ToNumber
      })
    },
    close() {
      this.view = false
      this.data = []
    },
    open(App_id, id) {
      this.query.App_id = App_id
      this.query.id = id
      this.getdata()
      this.view = true
    },
    openLoading(data) {
      if (!data) {
        data = '加载中'
      }
      this.loading = this.$loading({
        lock: true,
        text: data,
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)',
      });
    },

    closeLoading() {
      this.loading.close();
    },
  }
}
</script>

<style scoped lang="scss">
.fnRed {
  color: #910900;
  font-size: 11px;
}

.batch {
  float: left;
}

.tableClass { //table的class
  ::v-deep .el-table__fixed, ::v-deep .el-table__fixed-right {
    height: 100% !important; //设置高优先，以覆盖内联样式
  }
}


</style>
`