<template>
  <el-dialog v-model="view" width="800px" title="选择财务人员" :close-on-click-modal="false">
    <el-table
        v-loading="loading"
        :data="data"
        height="400px"
        border
    >
      <el-table-column prop="Name" label="姓名"></el-table-column>
      <el-table-column prop="Mobile" label="手机号码"></el-table-column>
      <el-table-column label="操作 ">
        <template #default="scope">
          <el-button type="text" @click="Chinoe(scope.row.Name,scope.row.Mobile)">选择</el-button>
        </template>
      </el-table-column>
    </el-table>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="close">关闭</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import {fetchData} from "../../../api/ProjectAdmin/Finance";

export default {
  data() {
    return {
      loading: true,
      App_id: '',
      view: false,
      data: []
    }
  },
  methods: {
    getData() {
      let App_id = this.App_id
      this.loading = true
      fetchData({App_id}).then(res => {
        this.data = res.sum
        this.loading = false
      })
    },
    Chinoe(Name, Mobile) {
      this.$parent.AddFinance(Name, Mobile)
      this.close()
    },
    close() {
      this.data = []
      this.view = false
    },
    open(App_id) {
      this.App_id = App_id
      this.view = true
      this.getData()
    }
  }
}
</script>

<style scoped>

</style>