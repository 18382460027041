<template>
  <div>
    <el-dialog v-model="edit" width="1300px">
      <el-table
          v-loading="loading"
          :data="data"
          height="500px"
          border
          class="tableClass"
          header-cell-class-name="table-header"
          ref="Table"
      >
        <el-table-column prop="Name" label="姓名" width="120"></el-table-column>
        <el-table-column prop="Mobile" label="手机号码" width="120"></el-table-column>
        <el-table-column :label="item+'（标签）'" v-for="(item, index) in header" :key="index" width="120">
          <template #default="scope">
            {{ scope.row.TolType[index].Number }} 元
          </template>
        </el-table-column>
        <el-table-column label="总金额" width="190">
          <template #default="scope">
            {{ scope.row.ToNumber }} 元
          </template>
        </el-table-column>
        <el-table-column prop="Channel" label="支付工具" width="90"></el-table-column>
        <el-table-column label="支付数据/凭证">
          <template #default="scope">
            <p v-if="scope.row.Channel=='支付宝'">订单编号：{{ scope.row.ContData.orderId }}</p>
            <p v-if="scope.row.Channel=='支付宝'">支付流水号：{{ scope.row.ContData.payFundOrderId }}</p>
            <p v-if="scope.row.Channel=='其他方式'&&scope.row.ContData.Radio=='文本'">{{scope.row.ContData.Voucher}}</p>
            <el-image
                v-if="scope.row.ContData.Radio=='图片'"
                style="width: 30px; height: 30px"
                :src="scope.row.ContData.Voucher"
                :preview-src-list="[scope.row.ContData.Voucher]">
            </el-image>
          </template>
        </el-table-column>
      </el-table>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="close">关闭</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import {fetchChanneData} from '../../../api/Bonus/Cpproval'

export default {
  data() {
    return {
      loading: true,
      data: [],
      edit: false,
      header: []
    }
  },
  methods: {
    close() {
      this.edit = false
      this.header = []
      this.data = []
    },
    open(id) {
      this.header = []
      this.data = []
      this.edit = true
      this.loading = true
      fetchChanneData({id}).then(res => {
        this.loading = false
        let data = res.initData

        let TypeData = data[0].TolType
        for (let item in TypeData) {
          this.header.push(TypeData[item].Type)
        }

        for (let i in data) {
          let ChannelData = data[i].ChannelData
          if (ChannelData) {
            ChannelData = JSON.parse(ChannelData)
            data[i].ChannelData = ChannelData
          }
        }

        setTimeout(() => {
          this.data = data
        }, 300)
      })
    }
  }
}
</script>

<style scoped lang="scss">
.tableClass { //table的class
  ::v-deep .el-table__fixed, ::v-deep .el-table__fixed-right {
    height: 100% !important; //设置高优先，以覆盖内联样式
  }

  ::v-deep .el-table th.gutter {
    display: table-cell !important;
  }
}


</style>
