import request from '../../utils/request';

/**
 * 财务成员管理
 * @param query
 * @returns {AxiosPromise}
 */

//查询项目总数据
export const FindProjec = query => {
    return request({
        url:process.env.VUE_APP_URL+ 'ProjectAdmin/Type/TolObject/find',
        method: 'get',
        params: query
    });
};

//查询数据
export const fetchData = query => {
    return request({
        url:process.env.VUE_APP_URL+ 'ProjectAdmin/Finance/find',
        method: 'get',
        params: query
    });
};


//添加财务人员
export const AddUser = query => {
    return request({
        url:process.env.VUE_APP_URL+ 'ProjectAdmin/Finance/add',
        method: 'post',
        data: query
    });
};

//删除财务人员
export const RemoveUser = query => {
    return request({
        url:process.env.VUE_APP_URL+ 'ProjectAdmin/Finance/remove',
        method: 'post',
        data: query
    });
};

