<template>
  <div class="DetailsGenerate">
    <div class="block" v-for="(item,index) in data" :key="index">
      <div class="class">
        <div class="icon red" v-bind:class="{green:item.class=='已签'}"></div>
      </div>
      <div class="title">签署人</div>
      <div class="name">{{item.name}}</div>
      <div class="title">状态</div>
      <div class="name">{{item.class}}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Array,
    }
  }

}
</script>

<style scoped lang="scss">
.DetailsGenerate {
  width: 200px;
  height: 580px;
  position: absolute;
  right: -200px;
  top: 0;
  background-color: #ffffff;
  overflow-y: auto;
  padding: 10px 0;
  border-left: 1px solid #f3f3f3;
  .block{
    width: 90%;
    height: 100px;
    background-color: #f3f3f3;
    border-radius: 5px;
    margin: 0 auto;
    font-size: 12px;
    padding: 10px 0;
    margin-bottom: 10px;
    .class{
      width: 100%;
      height: 10px;
      margin-bottom: 5px;
      .icon{
        width: 10px;
        height: 10px;
        border-radius: 50px;
        margin-left: 10px;
      }
      .red{
        background-color: red;
      }
      .green{
        background-color: green;
      }
    }
    .title{
      font-weight: bold;
      text-indent: 10px;
      margin-bottom: 5px;
    }
    .name{
      margin-bottom: 5px;
      text-indent: 10px;
    }
  }
}
</style>