import request from '../../utils/request';

/**
 * 项目查看与发起
 * @param query
 * @returns {AxiosPromise}
 */

export const fetchData = query => {
    return request({
        url:process.env.VUE_APP_URL+ 'ProjectAdmin/NumberAdmin/find',
        method: 'get',
        params: query
    });
};

/**
 * 申请项目发起
 * @param query
 * @returns {AxiosPromise}
 * @constructor
 */
export const ApplyProject= query => {
    return request({
        url:process.env.VUE_APP_URL+ 'ProjectAdmin/NumberAdmin/Apply',
        method: 'post',
        data: query
    });
};


export const AddData = query => {
    return request({
        url:process.env.VUE_APP_URL+ 'NumberAdmin/add',
        method: 'post',
        data: query
    });
};

export const UpData = query => {
    return request({
        url:process.env.VUE_APP_URL+ 'NumberAdmin/up',
        method: 'post',
        data: query
    });
};
export const Actions = () => {
    return process.env.VUE_APP_URL+'image/add'
};

/**
 * 增加额度
 * @param query
 * @returns {*}
 * @constructor
 */
export const NumberAdd = query => {
    return request({
        url:process.env.VUE_APP_URL+ 'ProjectAdmin/NumberAdmin/Number/add',
        method: 'post',
        data: query
    });
};

/**
 * 减少额度
 * @param query
 * @returns {*}
 * @constructor
 */

export const NumberReduce = query => {
    return request({
        url:process.env.VUE_APP_URL+ 'ProjectAdmin/NumberAdmin/Number/reduce',
        method: 'post',
        data: query
    });
};





